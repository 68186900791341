import * as yup from 'yup';
export const claimRewardSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  mobile: yup
    .number()
    .required('Mobile number is required')
    .typeError('Mobile number is invalid')
    .integer(),
  email: yup
    .string()
    .required('Email is required')
    .email('Email is invalid'),
});
