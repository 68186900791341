import appConfig from './apiConfig';

export const neoClaimRewardApi = data => {
  let postData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  let serverUrl = appConfig.azureFunctions.CLAIM_NEO_REWARD;

  const request = new Request(serverUrl, {
    method: 'POST',
    headers: headers,
    body: postData,
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      return error;
    });
};

export const isRewardIsExistApi = data => {
  let postData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  let serverUrl = appConfig.azureFunctions.CHECK_REWARD_IS_EXIST;

  const request = new Request(serverUrl, {
    method: 'POST',
    headers: headers,
    body: postData,
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      return error;
    });
};

export const verifyOtpAndSendNeoRewardApi = data => {
  let postData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  let serverUrl = appConfig.azureFunctions.VERIFY_OTP_AND_SEND_NEO_REWARD;

  const request = new Request(serverUrl, {
    method: 'POST',
    headers: headers,
    body: postData,
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      return error;
    });
};

export const resendClaimOtpApi = data => {
  let postData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  let serverUrl = appConfig.azureFunctions.RESEND_CLAIM_OTP;

  const request = new Request(serverUrl, {
    method: 'POST',
    headers: headers,
    body: postData,
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      return error;
    });
};
