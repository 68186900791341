import {
  getRedirectAppMessageApi,
  noSurveryRequiredByUserApi,
  updateOnGlobalSurveyApi,
  updateOnMarketPlaceApi,
  updateParticipantApi,
  trackParticipantsApi,
  vendorRedirectUpdateApi,
  clientUpdateRedirectUpdateApi,
  getRedirectUrlForUserAttemptApi
} from '../api/successApi';

export const setUpdateParticipant = updateParticipantResp => {
  return {
    type: 'SET_UPDATE_PARTICIPANT_RESPONSE',
    updateParticipantResp,
  };
};

export const setRedirectAppMessage = uiContent => {
  return {
    type: 'SET_UI_CONTENT',
    uiContent,
  };
};

export const setUpdateMarketPlaceCallback = result => {
  return {
    type: 'SET_UPDATE_MARKET_PLACE_ENDPOINT',
    result,
  };
};

export const setparticipantRedirectUrl = participantRedirectUrlRes => {
  return {
    type: 'SET_PARTICIPANT_REDIRECT_URL_RES',
    participantRedirectUrlRes,
  };
};

export const trackParticipantsResponse = response => {
  return {
    type: 'SET_TRACKING_RESPONSE',
    response,
  };
};

export const updateParticipant = data => {
  return dispatch => {
    updateParticipantApi(data).then(result => {
      dispatch(setUpdateParticipant(result));
    });
  };
};

export const getRedirectUrlForUserSurveyAttempt = (data) => {
  return dispatch => {
  getRedirectUrlForUserAttemptApi(data).then((result) => {
          dispatch(setUpdateParticipant(result));
  })
  }
}

export const updateClientAppRedirectUpdate = (data, url) => {
  return dispatch => {
      clientUpdateRedirectUpdateApi(data, url).then((result) => {});
  }
}


export const getUIContent = data => {
  return dispatch => {
    getRedirectAppMessageApi(data).then(result => {
      dispatch(setRedirectAppMessage(result));
    });
  };
};

export const updateOnMarketPlace = (RID, data) => {
  return dispatch => {
    updateOnMarketPlaceApi(RID, data).then(result => {
      dispatch(setUpdateMarketPlaceCallback(result));
    });
  };
};

export const updateOnGlobalSurvey = (UID, data) => {
  return dispatch => {
    updateOnGlobalSurveyApi(UID, data).then(result => {
      dispatch(setUpdateMarketPlaceCallback(result));
    });
  };
};

export const trackParticipants = url => {
  return dispatch => {
    trackParticipantsApi(url).then(result => {
      dispatch(trackParticipantsResponse(result));
    });
  };
};

export const vendorRedirectUpdate = (data, redirectVendor) => {
  return dispatch => {
    vendorRedirectUpdateApi(data, redirectVendor).then(result => {});
  };
};
