import { combineReducers } from 'redux';
import successStore from './successStore';
import neoReward from './neoReward';

const rootReducer = combineReducers({
  successStore,
  neoReward,
});

export default rootReducer;
