const initialState = {};

const neoReward = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADER':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_CLAIM_REWARD_RESPONSE':
      return {
        ...state,
        claimSuccessResponse: action.response,
        isLoading: false,
      };
    case 'SET_CLAIM_FAILED_REWARD_RESPONSE':
      return {
        ...state,
        claimFailedResponse: action.response,
        isLoading: false,
      };
    case 'SET_REWARD_IS_EXIST_RESPONSE':
      return {
        ...state,
        isRewardIsExist: action.response,
      };
    case 'SET_FAILED_VERIFY_OTP_AND_SEND_REWARD':
      return {
        ...state,
        verifyOtpFailed: action.response,
        isLoading: false,
      };
    case 'SET_VERIFY_OTP_AND_SEND_REWARD':
      return {
        ...state,
        verifyOtpResponse: action.response,
        isLoading: false,
      };
    case 'EMPTY_CLAIM_REWARD_RESPONSE':
      return {
        ...state,
        claimSuccessResponse: null,
        claimFailedResponse: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default neoReward;
