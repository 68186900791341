import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { submitClaimReward } from '../../actions/neoRewardAction';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { classNames } from 'primereact/utils';
import { claimRewardSchema } from './Validate';
import OtpForm from './OtpForm';
import { InputNumber } from 'primereact/inputnumber';

const ClaimReward = props => {
  const [isOtpForm, setisOtpForm] = useState(false);
  const [claimId, setClaimId] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(claimRewardSchema),
  });
  const toast = useRef(null);
  //hide modal
  const onHide = () => {
    props.hideClaimReward();
  };
  //show meesage after the otp verify
  const otpVerifyMsg = msg => {
    toast.current.show({ severity: 'success', summary: 'Success Message', detail: msg, life: 6000 });
  };
  //get claimed response
  useEffect(() => {
    if (props.claimSuccessResponse && props.claimSuccessResponse.success) {
      toast.current.show({ severity: 'success', summary: 'Success Message', detail: props.claimSuccessResponse.message, life: 6000 });
      setClaimId(props.claimSuccessResponse.id);
      setisOtpForm(true);
    }
  }, [props.claimSuccessResponse]);
  //check claimed failed response
  useEffect(() => {
    if (props.claimFailedResponse && !props.claimFailedResponse.success) {
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: props.claimFailedResponse.message, life: 6000 });
      onHide();
      props.hideRewardSection();
    }
  }, [props.claimFailedResponse]);
  //validation error
  const getFormErrorMessage = name => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  //form submit
  const onSubmit = data => {
    Object.assign(data, { UID: props.uid });
    props.dispatch(submitClaimReward(data));
  };
  return (
    <React.Fragment>
      <Toast ref={toast} />
      <Dialog draggable={false} header="Claim The Reward" visible={props.visible} style={{ width: '35vw' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} footer="" onHide={() => onHide()}>
        {!isOtpForm ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="name">
                  Enter your name <i>*</i>
                </label>
                <InputText id="name" name="name" {...register('name')} type="text" className={classNames({ 'p-invalid': errors.name })} />
                {getFormErrorMessage('name')}
              </div>
              <div className="p-field">
                <label htmlFor="emailId">
                  Enter your email id <i>*</i>
                </label>
                <InputText id="email" name="email" {...register('email')} type="text" className={classNames({ 'p-invalid': errors.email })} />
                {getFormErrorMessage('email')}
              </div>
              <div className="p-field">
                <label htmlFor="mobileNo">
                  Enter your mobile no <i>*</i>
                </label>
                <InputNumber
                  id="mobile"
                  name="mobile"
                  {...register('mobile')}
                  useGrouping={false}
                  className={classNames({ 'p-invalid': errors.mobile })}
                />
                <small>With country code without plus (+), Ex. 1XXXXXXXXXX</small>
                <br />
                {getFormErrorMessage('mobile')}
              </div>
            </div>
            <div className="p-field">
              <Button type="submit" label="Submit" loading={props.isLoading} />
            </div>
          </form>
        ) : (
          <OtpForm onHide={onHide} claimId={claimId} otpVerifyMsg={otpVerifyMsg} hideRewardSection={props.hideRewardSection} />
        )}
      </Dialog>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    isLoading: state.neoReward.isLoading,
    claimSuccessResponse: state.neoReward.claimSuccessResponse,
    claimFailedResponse: state.neoReward.claimFailedResponse,
  };
};
export default connect(mapStateToProps)(ClaimReward);
