import appConfig from "./apiConfig";

//Api For Updating Participant
export const updateParticipantApi = data => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    "Content-Type": "application/json"
  });
  let landingUrlString = encodeURIComponent(data.redirectedUrl);

  let serverUrl = appConfig.azureFunctions.PARTICIPANT_ROUTE_UPDATE;
  var allParams =
    "id=" +
    data.uid +
    "&checkLoi=" +
    data.checkLoi +
    "&sid=" +
    data.sid +
    "&status=" +
    data.status +
    "&redirectedUrl=" +
    landingUrlString;
  serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);

  const request = new Request(serverUrl, {
    method: "GET",
    headers: headers
    //  body: participantData
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      console.log("update participant", error);
      return error;
    });
};

export const getRedirectAppMessageApi = data => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    "Content-Type": "application/json"
  });

  let serverUrl = appConfig.azureFunctions.GET_UI_CONTENT;
  var allParams = "UID=" + data.uid + "&status=" + data.status;
  serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);

  const request = new Request(serverUrl, {
    method: "POST",
    headers: headers,
    body: participantData
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      console.log("update participant", error);
      return error;
    });
};

export const updateOnMarketPlaceApi = (RID, data) => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    "Content-Type": "application/json",
    authorization: "C4229E20-9CEB-4FD1-B76F-ED93FCA3E2B9"
  });
  const request = new Request(
    "https://callback.samplicio.us/callback/v1/status/" + RID,
    {
      method: "PUT",
      headers: headers,
      body: participantData
    }
  );

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      console.log("update participant", error);
      return error;
    });
};

export const updateOnGlobalSurveyApi = (UID, data) => {
  let dataToSent = {};
  dataToSent.status_id = 1;
  dataToSent.pid = UID;
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    mode: "no-cors",
    authorization:
      "Bearer fbfc7933f92114f6d2cdfb35265995f13fda1967573ff8f4bf4ad035717046179c88aed06a2200994b05e2d3194f67e527761e68a1c6320a00bed3aa4af4cfb5"
  });
  const request = new Request(
    "https://www.gowebsurveys.com/processS2S.php?status_id=1&pid=" + UID,
    {
      method: "POST",
      headers: headers
    }
  );

  return fetch(request)
    .then(response => {
      //return response.json().then(result => {
      return true;
      //});
    })
    .catch(error => {
      //console.log("update participant", error)
      return true;
    });
};

// noSurveryRequiredByUserApi
export const noSurveryRequiredByUserApi = data => {
  let declineUserData = JSON.stringify(data);
  const headers = Object.assign({
    "Content-Type": "application/json"
  });
  const request = new Request(
    appConfig.azureFunctions.NO_SURVEY_REQUIRED_BY_USER,
    {
      method: "POST",
      headers: headers,
      body: declineUserData
    }
  );

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      console.log("Create third party", error);
      return error;
    });
};

export const trackParticipantsApi = url => {
  const headers = Object.assign({
    "Content-Type": "application/json"
  });
  let landingUrlString = encodeURIComponent(url);
  let endPoint = appConfig.azureFunctions.TRACK_PARTICIPANTS;
  const allParams = "appName=complete&status=1&url=" + landingUrlString;
  endPoint = endPoint.replace("{ALLPARAMS}", allParams);
  const request = new Request(endPoint, {
    method: "GET",
    headers: headers
  });
  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      console.log("track participant", error);
      return error;
    });
};

export const vendorRedirectUpdateApi = (data, redirectUrl) => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    "Content-Type": "application/json"
  });
  let landingUrlString = encodeURIComponent(redirectUrl);
  let serverUrl = appConfig.azureFunctions.VENDOR_REDIRECT_UPDATE;
  var allParams = "pid=" + data.uid + "&redirectUrl=" + landingUrlString;
  serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);

  const request = new Request(serverUrl, {
    method: "GET",
    headers: headers
    //body: participantData
  });

  return fetch(request)
    .then(response => {
      return response.json().then(result => {
        return result;
      });
    })
    .catch(error => {
      return error;
    });
};

export const clientUpdateRedirectUpdateApi = (data, redirectUrl) => {
        const headers = Object.assign({ 'Content-Type': 'application/json' });
        let landingUrlString = encodeURIComponent(redirectUrl);

        let serverUrl = appConfig.azureFunctions.CLIENT_REDIRECT_UPDATE;
        var allParams = "studyId=" + data.SID + "&pid=" + data.PID + "&redirectUrl=" + landingUrlString;
        serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);


        debugger
        const request = new Request(serverUrl, {
            method: "GET",
            headers: headers,
            //body: participantData
        });
    
        return fetch(request).then(response => {
            return response.json().then(result => {
                return result;
            });
        }).catch(error => {
            return error;
        });

}

export const getRedirectUrlForUserAttemptApi = (data) => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
      'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.azureFunctions.GET_REDIRECT_URL_FOR_USER_ATTEMPT, {
      method: "POST",
      headers: headers,
      body: participantData
  });

  return fetch(request).then(response => {
      return response.json().then(result => {
          return result;
      });
  }).catch(error => {
      return error;
  });
}
