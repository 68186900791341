import { neoClaimRewardApi, isRewardIsExistApi, verifyOtpAndSendNeoRewardApi, resendClaimOtpApi } from '../api/neoRewardApi';

export const setClaimRewardResponse = response => {
  return {
    type: 'SET_CLAIM_REWARD_RESPONSE',
    response,
  };
};
export const setClaimRewardFailedResponse = response => {
  return {
    type: 'SET_CLAIM_FAILED_REWARD_RESPONSE',
    response,
  };
};
export const emptyClaimRewardResponse = response => {
  return {
    type: 'EMPTY_CLAIM_REWARD_RESPONSE',
    response,
  };
};
export const setResponseRewardIsExist = response => {
  return {
    type: 'SET_REWARD_IS_EXIST_RESPONSE',
    response,
  };
};
export const setOtpVerifyAndSendRewardResponse = response => {
  return {
    type: 'SET_VERIFY_OTP_AND_SEND_REWARD',
    response,
  };
};
export const setOtpVerifyAndSendRewardFailed = response => {
  return {
    type: 'SET_FAILED_VERIFY_OTP_AND_SEND_REWARD',
    response,
  };
};
export const setLoader = response => {
  return {
    type: 'SET_LOADER',
    response,
  };
};

export const submitClaimReward = data => {
  return dispatch => {
    dispatch(setLoader());
    neoClaimRewardApi(data).then(result => {
      if (result.success) {
        dispatch(setClaimRewardResponse(result));
      } else {
        dispatch(setClaimRewardFailedResponse(result));
      }
    });
  };
};

export const isCheckRewardIsExist = data => {
  return dispatch => {
    isRewardIsExistApi(data).then(result => {
      if (result.success) {
        dispatch(setResponseRewardIsExist(result));
      }
    });
  };
};

export const verifyOtpAndSendNeoReward = data => {
  return dispatch => {
    dispatch(setLoader());
    verifyOtpAndSendNeoRewardApi(data).then(result => {
      if (result.success) {
        dispatch(setOtpVerifyAndSendRewardResponse(result));
      } else {
        dispatch(setOtpVerifyAndSendRewardFailed(result));
      }
    });
  };
};

export const resendClaimOtp = data => {
  return dispatch => {
    resendClaimOtpApi(data).then(result => {
      if (result.success) {
        alert(result.message);
      } else {
        alert(result.message);
      }
    });
  };
};
