import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { classNames } from 'primereact/utils';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { verifyOtpAndSendNeoReward, resendClaimOtp } from '../../actions/neoRewardAction';
import { Toast } from 'primereact/toast';

const schema = yup.object().shape({
  emailOtp: yup.string().required('Email otp is required'),
  smsOtp: yup.string().required('Mobile otp is required'),
});
const OtpForm = props => {
  const toast = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const getFormErrorMessage = name => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>;
  };
  //resend otp
  const resendOtp = (id, type) => {
    const data = {
      id,
      type,
    };
    props.dispatch(resendClaimOtp(data));
  };
  //success response of verify otp
  useEffect(() => {
    if (props.verifyOtpResponse && props.verifyOtpResponse.success) {
      props.otpVerifyMsg(props.verifyOtpResponse.message);
      props.onHide();
      props.hideRewardSection();
      if (props.updateParticipantResp.holdRedirect){
        // 
        console.log("Its voxco user hold it");
      } else {
        if (props.updateParticipantResp.isRedirect) {
          setTimeout(function() {
            window.location.href = props.updateParticipantResp.redirectUrl;
          }, 2000);
        }
      }
    }
  }, [props.verifyOtpResponse]);
  //failed response of verify otp
  useEffect(() => {
    if (props.verifyOtpFailed && !props.verifyOtpFailed.success) {
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: props.verifyOtpFailed.message, life: 6000 });
    }
  }, [props.verifyOtpFailed]);
  const onSubmit = data => {
    Object.assign(data, { id: props.claimId });
    props.dispatch(verifyOtpAndSendNeoReward(data));
  };
  return (
    <div>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="name">
              Enter Email OTP <i>*</i>
            </label>
            <InputText name="emailOtp" maxLength="4" {...register('emailOtp')} type="text" className={classNames({ 'p-invalid': errors.emailOtp })} />
            <span onClick={() => resendOtp(props.claimId, 'email')} style={{ float: 'right', cursor: 'pointer' }}>
              Resend OTP
            </span>
            <br />
            {getFormErrorMessage('emailOtp')}
          </div>
          <div className="p-field">
            <label htmlFor="name">
              Enter Mobile OTP <i>*</i>
            </label>
            <InputText name="smsOtp" maxLength="4" {...register('smsOtp')} type="text" className={classNames({ 'p-invalid': errors.smsOtp })} />
            <span onClick={() => resendOtp(props.claimId, 'mobile')} style={{ float: 'right', cursor: 'pointer' }}>
              Resend OTP
            </span>
            <br />
            {getFormErrorMessage('smsOtp')}
          </div>
        </div>
        <div className="p-field">
          <Button type="submit" label="Submit" loading={props.isLoading} />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.neoReward.isLoading,
    verifyOtpFailed: state.neoReward.verifyOtpFailed,
    verifyOtpResponse: state.neoReward.verifyOtpResponse,
    updateParticipantResp: state.successStore.updateParticipantResp,
  };
};
export default connect(mapStateToProps)(OtpForm);
