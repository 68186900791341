import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getUIContent,
  updateOnGlobalSurvey,
  updateOnMarketPlace,
  updateParticipant,
  trackParticipants,
  vendorRedirectUpdate,
  updateClientAppRedirectUpdate,
  getRedirectUrlForUserSurveyAttempt
} from '../../actions/successAction';
import './style.css';
import './main.css';
import { FormattedMessage } from 'react-intl';
import { Button } from 'primereact/button';
import ClaimReward from '../Partials/ClaimReward';
import { isCheckRewardIsExist } from '../../actions/neoRewardAction';
import { getUserSurveyList } from "../elements/userSurveyList";
import { Alert,Row, Col } from 'antd';

class StudySuccess extends Component {
  constructor(props) {
    super(props);
    this.queryString = window.location.search;
    this.queryStringHref = window.location.href;
    this.allQueryStrings = window.location.search;
    this.queryOrigion = window.location.origin;
    this.queryPathname = window.location.pathname;
    let origionNotHttp = this.queryOrigion.replace(/(^\w+:|^)\/\//, '');
    this.origionNotHttp1 = origionNotHttp + this.queryPathname + this.allQueryStrings;

    if (this.queryString !== '') {
      let searchParams = getUrlParam('UID', 'Empty');
      if(searchParams === "xxxx"){
        searchParams = getUrlParam("pid", "Empty");
      } else if (searchParams === 'Empty') {
        searchParams = getUrlParam('uid', 'Empty');
      }
      if (searchParams && searchParams !== 'Empty') {
        this.uid = searchParams;
        this.sid = 'sid';
      }
    }

    this.state = {
      uid: this.uid,
      sid: this.sid,
      terminateUrlMainSurvey: '',
      redirectedUrl: this.queryStringHref,
      marketPlaceCalled: false,
      participantRedirectUrl: '',
      headerMessage: '',
      bodyMessage: '',
      participantUpdateData: {},
      isUpdateParticipantCalled: false,
      showRewardModal: false,
      isShowRewardSection: false,
      isClientAppRedirect : false,
      isFirstPartyRedirectCalled : false,
    };

    this.updateParticipant = this.updateParticipant.bind(this);
    this.updateClientAppRedirect = this.updateClientAppRedirect.bind(this);
    this.updateVendorRedirect = this.updateVendorRedirect.bind(this);
    this.hideClaimReward = this.hideClaimReward.bind(this);
    this.openRewardModal = this.openRewardModal.bind(this);
    this.hideRewardSection = this.hideRewardSection.bind(this);
    this.onMultipleAttemptClick = this.onMultipleAttemptClick.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(trackParticipants(window.location.href));
    if (this.queryString !== '') {
      let searchParams = getUrlParam('UID', 'Empty');
      if (searchParams === 'Empty') {
        searchParams = getUrlParam('uid', 'Empty');
      }
      if (searchParams && searchParams !== 'Empty') {
        this.updateParticipant();
      } else {
        alert('You are requesting with bad parameters.');
      }
    } 
  }

  updateParticipant() {
    let participantData = {};
    participantData.sid = this.state.sid;
    participantData.status = 1;
    participantData.uid = this.state.uid;
    participantData.redirectedUrl = this.origionNotHttp1;
    participantData.checkLoi = true;
    this.setState({ participantUpdateData: participantData });
    //this.props.dispatch(getUIContent(participantData));
    this.props.dispatch(updateParticipant(participantData));
  }

  onMultipleAttemptClick(item, clientType, isFirstAttempt, parentPID) {
    let dataToPass = {};

    if (clientType == 11 || clientType == 2) {
      dataToPass.clientProjectId = item.ProjectId;
      dataToPass.LOI = item.SurveyLength;
      dataToPass.IR = item.Ir;
      dataToPass.CPI = item.MemberReward;
      dataToPass.PID = parentPID;
      dataToPass.apiType = clientType;
      dataToPass.isFirstAttempt = isFirstAttempt;
      dataToPass.surveyUrl = item.SurveyUrl;
      dataToPass.landingUrl = this.origionNotHttp1;
    }
    this.props.dispatch(getRedirectUrlForUserSurveyAttempt(dataToPass));
  }

  updateClientAppRedirect(url) {
    debugger
    setTimeout(function() {
      window.location.href = url;
    }, 1000);
    if (this.state.isClientAppRedirect === false) {
      this.setState({ isClientAppRedirect: true });
      this.props.dispatch(vendorRedirectUpdate(this.state.participantUpdateData, url));
    }
  }


  updateFirstPartyRedirectToPID(data, url) {
    setTimeout(function() {
      window.location.href = url;
    }, 1000);
    if (this.state.isFirstPartyRedirectCalled === false) {
      this.setState({ isFirstPartyRedirectCalled: true });
      this.props.dispatch(
        updateClientAppRedirectUpdate(data, url)
      );
    }
  }



  updateVendorRedirect(url) {

    if (this.state.isClientAppRedirect === false) {
      debugger
      this.setState({ isShowRewardSection: true });
      this.setState({ isClientAppRedirect: true });
      this.props.dispatch(vendorRedirectUpdate(this.state.participantUpdateData, url));
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateParticipantResp && nextProps.updateParticipantResp.success) {
     
      debugger
      if (nextProps.updateParticipantResp.isProjectReward){
        this.updateVendorRedirect(nextProps.updateParticipantResp.redirectUrl)
      } else {
        if (nextProps.updateParticipantResp.holdRedirect){
          // 
          console.log("Its voxco user hold it");
        } else {
          if (nextProps.updateParticipantResp.isRedirect) {
            if(nextProps.updateParticipantResp.hasOwnProperty("isFirstPartyRedirect")
                && nextProps.updateParticipantResp.isFirstPartyRedirect == true){
                  // Track Client App Redirect For Routing Here
                  this.updateFirstPartyRedirectToPID(nextProps.updateParticipantResp, nextProps.updateParticipantResp.redirectUrl);
            } else {
              this.updateClientAppRedirect(nextProps.updateParticipantResp.redirectUrl); 
            }
          }
        }
      }
    } else {
      this.setState({ bodyMessage: nextProps.updateParticipantResp.message });
    }

    if (nextProps.uiContent && nextProps.uiContent.success) {
      if (this.state.isUpdateParticipantCalled === false) {
        this.setState({
          headerMessage: nextProps.uiContent.headerMessage,
          bodyMessage: nextProps.uiContent.message,
          isUpdateParticipantCalled: true,
        });
      }
    }
  }

  //hide reward modal
  hideClaimReward() {
    this.setState({ showRewardModal: false });
  }
  //show reward modal
  openRewardModal() {
    this.setState({ showRewardModal: true });
  }
  //hide reward section
  hideRewardSection() {
    this.setState({ isShowRewardSection: false });
  }

  render() {
    let messageShow ="";
    let showOverQuotaPage = (
      <main>
        <div className="round"> 
             <p style={{fontsize : "142%"}}>
                <FormattedMessage id="app.msg.redirected" />
              </p>
          <div className="round-circle"></div>
          <div className="round-caption">
            <h2 className="modal-title ">
              <FormattedMessage id="app.msg.name" />
            </h2>
            <p>
              <FormattedMessage id="app.mgs.content" />
            </p>
            {this.state.isShowRewardSection ? (
              <React.Fragment>
                <p style={{ fontSize: '14px' }}>Congratulations! We have a reward for you. Please claim this by clicking below</p>
                <Button onClick={this.openRewardModal} label="Claim Reward" className="p-button-rounded p-button-help" />
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="right-image">
          <img src={require('../../Image/student-experience.png')} alt="study" class="" />
        </div>
      </main>
    );

    
    if(this.props.updateParticipantResp && 
      this.props.updateParticipantResp.hasOwnProperty("TID") &&
      this.props.updateParticipantResp.TID == "2820217VENDOR1630129708881" && 
      (this.props.updateParticipantResp.apiType == 11  || this.props.updateParticipantResp.apiType == 2)
    ) {
      messageShow =  <Alert
      message="Success "
      description="Your points have been updated in ZampliaSurveys Dashboard"
      type="success"
       showIcon
       closable
       style={{top : "75px"}}
     />
    }
    // Check If This is available for  routing here
    if (
      this.props.updateParticipantResp &&
      this.props.updateParticipantResp.hasOwnProperty("isMultipleWindow") &&
      this.props.updateParticipantResp.isMultipleWindow === true
    ) {
      showOverQuotaPage = getUserSurveyList(
        this.props.updateParticipantResp.userSurveys,
        this.props.updateParticipantResp.apiType,
        this.props.updateParticipantResp.isFirstAttempt,
        this.onMultipleAttemptClick,
        this.props.updateParticipantResp.PID
      );
    }

    return (
      <div className="animate-bottom">
        <Row gutter={16}>
          <Col className="gutter-row" span={20} offset={2}>
            {messageShow}
          </Col>
        </Row>
        {showOverQuotaPage}
        <ClaimReward
          uid={this.state.uid}
          visible={this.state.showRewardModal}
          hideRewardSection={this.hideRewardSection}
          hideClaimReward={this.hideClaimReward}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    updateParticipantResp: state.successStore.updateParticipantResp,
    lastTerminate: state.successStore.lastTerminate,
    updateMarketPlaceResult: state.successStore.updateMarketPlaceResult,
    uiContent: state.successStore.uiContent
  };
};

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
}

export default connect(mapStateToProps)(StudySuccess);
