const initialState = {
    updateParticipantResp: ""
}

const successStore = (state = initialState, action) => {
    switch (action.type) {
        case "SET_UPDATE_PARTICIPANT_RESPONSE":
            return {
                ...state,
                participantNewStudyUrlSuccess: false,
                updateParticipantResp: action.updateParticipantResp,
                lastTerminate: null
            }
        case 'SET_PARTICIPANT_REDIRECT_URL_RES':
            return {
                ...state,
                updateParticipantResp: false,
                participantNewStudyUrlSuccess: action.participantRedirectUrlRes.newSurveyParticipant,
                participantNewRedirectUrlData: action.participantRedirectUrlRes.data,

            }
        case "SET_NO_SURVEY_REQUIRE" :
            return {
                ...state,
                lastTerminate: action.nosurveyreqResult,
                updateParticipantResp: null
            }
        case "SET_UI_CONTENT" :
            return {
                ...state,
                uiContent: action.uiContent
            }
        case "SET_UPDATE_MARKET_PLACE_ENDPOINT" :
            return {
                ...state,
                updateMarketPlaceResult: action.result
            }
        case "SET_TRACKING_RESPONSE" :
            return{
                ...state,
                trackingResponse : action.response
            }
        default:
            return state;
    }

};
export default successStore;
  